<template>
  <div class="add_goods_content">
    <page-content>
      <search-panel
        flex
        show-btn
        @getList="getListChange"
      >
        <div>
          <p>商品:</p>
          <el-input
            v-model.trim="searchParams.goodsName"
            placeholder="请输入商品名称"
            size="mini"
            clearable
          />
        </div>
        <el-button
          slot="button-right"
          size="mini"
          type="primary"
          @click="goChooseGoods"
        >
          选择商品
        </el-button>
      </search-panel>
      <el-table
        v-loading="loading"
        :data="tableData"
        border
        style="width: 100%"
        element-loading-text="拼命加载中"
      >
        <el-table-column
          prop="id"
          label="ID"
          width="90"
        />
        <el-table-column
          prop="status"
          label="商品图片"
          width="150"
        >
          <template slot-scope="scope">
            <div>
              <img
                :src="scope.row.imagePath"
                alt=""
                style="width: 100px;height: 100px;"
              >
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="name"
          label="商品名称"
          width="120"
        />
        <el-table-column
          prop="validStartTime"
          label="分销时间"
        >
          <template slot-scope="scope">
            {{ dayjs(Number(scope.row.validStartTime)).format('YYYY-MM-DD HH:mm') }} 至 {{ dayjs(Number(scope.row.validEndTime)).format('YYYY-MM-DD HH:mm') }}
            <el-button
              type="text"
              icon="el-icon-edit"
              circle
              @click="timeVisibleShow(scope.$index, scope.row)"
            ></el-button>
          </template>
        </el-table-column>
        <el-table-column
          prop="price"
          label="操作"
          fixed="right"
          width="100"
          align="center"
        >
          <template slot-scope="scope">
            <el-button
              type="text"
              size="mini"
              @click="editGoods(scope.row)"
            >
              编辑
            </el-button>
            <el-divider direction="vertical" />
            <el-button
              type="text"
              style="color: red;"
              size="mini"
              @click="deleteGoods(scope.row)"
            >
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :current-page="pagination.page"
        :page-sizes="[10, 20, 30, 50]"
        :page-size="pagination.page_count"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pagination.item_total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </page-content>
    <!-- 修改商品库存弹框 -->
    <add-goods-dialog
      v-if="changeGoodsVisible"
      :change-goods-visible.sync="changeGoodsVisible"
      :goods-row="changeGoodsRow"
      :open-type="openType"
      @onupdatetable="getList()"
    />

    <!-- 分销时间弹窗 -->
    <el-dialog
      title="分销时间"
      :visible.sync="timeVisible"
    >
      <el-date-picker
        v-model="commissionTime"
        type="datetimerange"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        value-format="timestamp"
        style="width: 500px"
        :default-time="['00:00:00', '23:59:59']"
      >
      </el-date-picker>
      <span
        slot="footer"
      >
        <el-button @click="timeVisible = false">取 消</el-button>
        <el-button
          type="primary"
          @click="editCommissionTime"
        >确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import addGoodsDialog from './components/addAndEditGoods.vue'
import dayjs from 'dayjs'

export default {
  name: 'AddGoods',
  components: {
    addGoodsDialog
  },
  data() {
    return {
      dayjs,
      searchParams: {
        goodsName: ''
      },
      loading: false,
      tableData: [],
      pagination: {
        page: 1,
        page_total: 0,
        item_total: 0,
        page_count: 10
      },
      changeGoodsVisible: false,
      changeGoodsRow: {},
      openType: 'edit',

      commissionVisible: false, // 佣金弹窗
      commissionValue: '', // 佣金金额
      commissionId: null,
      commissionMax: 0,
      commissionTime: [],
      timeVisible: false

    }
  },
  mounted() {
    this.getList()
  },

  methods: {

    // 去选择商品yemian
    goChooseGoods() {
      this.$router.push({
        path: '/nb/distribution/addGoods',
        query: {
          // promId: this.$route.query.id
        }
      })
    },

    // 编辑分销时间
    timeVisibleShow(index, row) {
      this.commissionId = row.id
      this.timeVisible = true
      this.commissionTime = [row.validStartTime, row.validEndTime]
    },
    editCommissionTime() {
      if (!this.commissionTime || !this.commissionTime.length || this.commissionTime.length < 2) {
        this.$message.error('请选择分销时间')
        return
      }
      this.$axios(this.$api.DistributeController.updateGoodsTime, {
        params: {
          goodsid: this.commissionId,
          starttime: this.commissionTime[0],
          endtime: this.commissionTime[1]
        }
      }).then(res => {
        if (res.code === 0) {
          this.timeVisible = false
          this.getList()
        }
      })
    },

    editGoods(row) {
      this.changeGoodsVisible = true
      this.changeGoodsRow = row
    },

    // 删除
    deleteGoods(row) {
      this.$confirm('是否确认删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$axios(this.$api.DistributeController.delSelectGoods, {
          params: {
            goodsid: row.id
          }
        }).then(res => {
          if (res.code === 0) {
            this.getList()
          }
        })
      }).catch(() => {})
    },
    // 分页控制
    handleSizeChange(val) {
      this.pagination.page_count = val
      this.pagination.page = 1
      this.getList()
    },
    handleCurrentChange(val) {
      this.pagination.page = val
      this.getList()
    },
    getListChange() {
      this.pagination.page = 1
      this.getList()
    },

    getList() {
      let _this = this
      _this.loading = true
      let params = {
        ...this.searchParams,
        currentPage: _this.pagination.page,
        pageSize: _this.pagination.page_count
      }
      _this.$axios.post(
        _this.$api.DistributeController.getSelectGoods,
        params
      ).then(function(res) {
        if (res.code === 0) {
          const { size, total, current, pages } = res.data;
          _this.pagination = {
            page: Number(current),
            page_count: Number(size),
            page_total: Number(pages),
            item_total: Number(total)
          }
          _this.tableData = res.data.records
        } else {
          _this.tableData = []
          _this.pagination.item_total = 0
        }
        _this.loading = false
      }).catch(function(error) {
        console.log(error)
      })
    }
  }

}
</script>

<style lang="scss" scoped>

</style>
<style lang="scss">
.goods_message {
    display: inline-flex;
    align-items: center;
    justify-content: space-around;
    .left {
      margin: 10px 15px;
      img {
        width: 80px;
        height: 80px;
      }
    }
    .right {
      .title {
        font-weight: 700;
        margin: 10px 0;
      }
      .id {
        color: #999;
      }
    }
  }
</style>
