var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.openType == "edit" ? "修改分销商品" : "参加分销",
        visible: _vm.changeGoodsVisible,
        "before-close": _vm.closeHandle,
        width: "50%",
      },
      on: {
        "update:visible": function ($event) {
          _vm.changeGoodsVisible = $event
        },
      },
    },
    [
      _c(
        "el-table",
        {
          staticStyle: { width: "100%" },
          attrs: { data: _vm.skuTableData, border: "" },
        },
        [
          _c("el-table-column", {
            attrs: { prop: "goodsId", label: "商品id", "min-width": "110" },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "sellMinPrice",
              label: "商品最低销售价(元)",
              "min-width": "110",
            },
          }),
          _c("el-table-column", {
            attrs: { prop: "skuId", label: "skuId", "min-width": "110" },
          }),
          _c("el-table-column", {
            attrs: { prop: "sku", label: "sku", "min-width": "110" },
          }),
          _c("el-table-column", {
            attrs: { label: "佣金(元)", "min-width": "110" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("el-input", {
                      attrs: { size: "mini" },
                      model: {
                        value: scope.row.commission,
                        callback: function ($$v) {
                          _vm.$set(scope.row, "commission", $$v)
                        },
                        expression: "scope.row.commission",
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.onSure } },
            [_vm._v(_vm._s(_vm.openType == "edit" ? "确认修改" : "确认参加"))]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }