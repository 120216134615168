var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "add_goods_content" },
    [
      _c(
        "page-content",
        [
          _c(
            "search-panel",
            {
              attrs: { flex: "", "show-btn": "" },
              on: { getList: _vm.getListChange },
            },
            [
              _c(
                "div",
                [
                  _c("p", [_vm._v("商品:")]),
                  _c("el-input", {
                    attrs: {
                      placeholder: "请输入商品名称",
                      size: "mini",
                      clearable: "",
                    },
                    model: {
                      value: _vm.searchParams.goodsName,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.searchParams,
                          "goodsName",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "searchParams.goodsName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    slot: "button-right",
                    size: "mini",
                    type: "primary",
                  },
                  on: { click: _vm.goChooseGoods },
                  slot: "button-right",
                },
                [_vm._v(" 选择商品 ")]
              ),
            ],
            1
          ),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.tableData,
                border: "",
                "element-loading-text": "拼命加载中",
              },
            },
            [
              _c("el-table-column", {
                attrs: { prop: "id", label: "ID", width: "90" },
              }),
              _c("el-table-column", {
                attrs: { prop: "status", label: "商品图片", width: "150" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", [
                          _c("img", {
                            staticStyle: { width: "100px", height: "100px" },
                            attrs: { src: scope.row.imagePath, alt: "" },
                          }),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "name", label: "商品名称", width: "120" },
              }),
              _c("el-table-column", {
                attrs: { prop: "validStartTime", label: "分销时间" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm
                                .dayjs(Number(scope.row.validStartTime))
                                .format("YYYY-MM-DD HH:mm")
                            ) +
                            " 至 " +
                            _vm._s(
                              _vm
                                .dayjs(Number(scope.row.validEndTime))
                                .format("YYYY-MM-DD HH:mm")
                            ) +
                            " "
                        ),
                        _c("el-button", {
                          attrs: {
                            type: "text",
                            icon: "el-icon-edit",
                            circle: "",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.timeVisibleShow(
                                scope.$index,
                                scope.row
                              )
                            },
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "price",
                  label: "操作",
                  fixed: "right",
                  width: "100",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.editGoods(scope.row)
                              },
                            },
                          },
                          [_vm._v(" 编辑 ")]
                        ),
                        _c("el-divider", { attrs: { direction: "vertical" } }),
                        _c(
                          "el-button",
                          {
                            staticStyle: { color: "red" },
                            attrs: { type: "text", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.deleteGoods(scope.row)
                              },
                            },
                          },
                          [_vm._v(" 删除 ")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c("el-pagination", {
            attrs: {
              "current-page": _vm.pagination.page,
              "page-sizes": [10, 20, 30, 50],
              "page-size": _vm.pagination.page_count,
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.pagination.item_total,
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange,
            },
          }),
        ],
        1
      ),
      _vm.changeGoodsVisible
        ? _c("add-goods-dialog", {
            attrs: {
              "change-goods-visible": _vm.changeGoodsVisible,
              "goods-row": _vm.changeGoodsRow,
              "open-type": _vm.openType,
            },
            on: {
              "update:changeGoodsVisible": function ($event) {
                _vm.changeGoodsVisible = $event
              },
              "update:change-goods-visible": function ($event) {
                _vm.changeGoodsVisible = $event
              },
              onupdatetable: function ($event) {
                return _vm.getList()
              },
            },
          })
        : _vm._e(),
      _c(
        "el-dialog",
        {
          attrs: { title: "分销时间", visible: _vm.timeVisible },
          on: {
            "update:visible": function ($event) {
              _vm.timeVisible = $event
            },
          },
        },
        [
          _c("el-date-picker", {
            staticStyle: { width: "500px" },
            attrs: {
              type: "datetimerange",
              "range-separator": "至",
              "start-placeholder": "开始日期",
              "end-placeholder": "结束日期",
              "value-format": "timestamp",
              "default-time": ["00:00:00", "23:59:59"],
            },
            model: {
              value: _vm.commissionTime,
              callback: function ($$v) {
                _vm.commissionTime = $$v
              },
              expression: "commissionTime",
            },
          }),
          _c(
            "span",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.timeVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.editCommissionTime },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }